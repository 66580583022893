@import "../../../variables.scss";

.season-list-item
{
    list-style-type: none;
	padding: $spacing-medium $spacing-medium 0px $spacing-medium;

}

.season-list-content
{
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media (min-width: $mobile-break)
{
    .season-list-content
    {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}
