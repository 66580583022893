@import "../../../variables.scss";

.notFoundHeader
{
	font-size: 1em;
}

.notFoundMessage
{
	text-align: center;
}
