@import "../../../variables.scss";

.blind
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: $gray;
	background: RGBA(0,0,0,0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
