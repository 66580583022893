@import "../../../variables.scss";


.tip
{
	border: 1px solid $black;
	border-radius: $spacing-tiny;
	background: white;
}

.timeTitle
{
	display: block;
	text-align: center;
	margin: $spacing-small 0px $spacing-small $spacing-small;
	font-weight: bold;
}

.tooltipTable
{
	font-size: 0.75em;
	padding: 0px;
	margin: 0px $spacing-large $spacing-large $spacing-large;
}

.name
{
	padding: $spacing-tiny;
}

.value
{
	text-align: right;
	padding: $spacing-tiny $spacing-tiny $spacing-tiny $spacing-large;
}
