@import "../../../../variables.scss";

.formWhite p:not(:last-child),
.formBlack p:not(:last-child)
{
	margin-bottom: $spacing-medium;
}

.formWhite span,
.formBlack span
{
	display: block;
	padding: 0px;
	margin: 0px 0px $spacing-tiny 0px;
}

input
{
	width: 500px;
	display: block;
	margin: 0px;
	padding: $spacing-small;
}
