@import "../../../variables.scss";

.place-name
{
	text-align: left;
//	padding: $spacing-tiny;
}

.place-rebuys
{
	text-align: right;
//	padding: $spacing-tiny;
}

.place-place
{
	text-align: right;
//	padding: $spacing-tiny;
}

.place-knockOuts
{
	text-align: right;
//	padding: $spacing-tiny;
}

.place-points
{
	text-align: right;
	//padding: $spacing-tiny;
}

.place-vpip
{
	text-align: right;
//	padding: $spacing-tiny;
}

.place-pfr
{
	text-align: right;
//	padding: $spacing-tiny;
}

.place-style
{
	text-align: left;
//	padding: $spacing-tiny;
}


@media only screen and (min-width: 768px)
{

}
