@import "../../../../variables.scss";

.return-link
{
	padding-top: $spacing-tiny;
	text-align: center;
}

.league-admin-container
{
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	padding: 0;
}

.league-admin-container .block
{
	flex-grow: 1;
}

.league-admin-container .block:not(:last-child)
{
	margin-bottom: $spacing-large;
}

@media only screen and (min-width: 768px)
{
	.league-admin-container
	{
		flex-direction: row;
	}

	.league-admin-container .block:not(:last-child)
	{
		margin-right: $spacing-medium;
	}

	.league-admin-container .block:not(:first-child)
	{
		margin-left: $spacing-medium;
	}

	.league-admin-container .block:not(:last-child)
	{
		margin-bottom: 0px;
	}
}
