@import "../../variables.scss";

.admin-container
{
	padding: $spacing-tiny;
}

@media only screen and (min-width: $mobile-break)
{
	.admin-container
	{
		padding: $spacing-large;
	}
}
