@import "../../../variables.scss";

.knockouts-table
{
	width: 100%;
}

.knockouts-out
{
	text-align: left;
}

.knockouts-time
{
	text-align: right;
}

.knockouts-koby
{
	text-align: right;
}

@media only screen and (min-width: 768px)
{

}
