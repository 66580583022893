@import "./variables.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body
{
	background-color: $gray;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Lexend', sans-serif;
	font-size: 16pt;
}

@font-face
{
	font-family: 'ApexBold';
	src: url("./fonts/ApexMk2-BoldExtended.otf") format('opentype');
}

@font-face
{
	font-family: 'ApexLight';
	src: url("./fonts/ApexMk2-LightCondensed.otf") format('opentype');
}

@font-face
{
	font-family: 'ApexRegular';
	src: url("./fonts/ApexMk2-Regular.otf") format('opentype');
}

input
{
	font-family: 'Lexend';
	font-size: 1em;
	border-radius: $spacing-small;
	border: 2px $black solid;
	background-color: $gray;
}

input[type="checkbox"]
{

}

select
{
	font-family: 'Lexend', Sans-Serif;
	font-size: 1em;
	border-radius: 2px;
	border: 2px $black solid;
	background-color: $gray;
}


label
{
	font-family: 'Lexend';
	font-size: 1em;
}

table
{
	font-size: 1em;
	padding: 0;
	margin: 0;
	border: none;
	border-collapse: collapse;
}

table td
{
	padding: $spacing-small 0px;
	border-width: 1px 0; /*  1px top & bottom border,  0px left & right */
    border-color: rgba(255, 255, 255, 0.25);
    border-style: solid;
}

table td:not(:last-child),
table th:not(:last-child)
{
	padding-right: $spacing-medium;
}

.block table
{
	color: $gray;
}

p
{
	padding: 0px 0px $spacing-small 0px;
	margin: 0px;
}

h1
{
	font-family: ApexBold;
	text-align: center;
	font-size: 3em;
	padding: $spacing-large 0px $spacing-medium 0px;
	margin: 0px;
}

h2
{
	font-family: ApexLight;
	text-align: center;
	font-size: 2em;
	padding: $spacing-large 0px $spacing-medium 0px;
	margin: 0px;
}

h3
{
	font-family: ApexRegular;
	text-align: center;
	font-size: 1.5em;
	padding: $spacing-large 0px $spacing-medium 0px;
	margin: 0px;
}

h4
{
	font-family: Lexend;
	text-align: center;
	font-size: 1em;
	padding: $spacing-tiny 0px $spacing-tiny 0px;
	margin: 0px;
}

button
{
	padding: $spacing-small $spacing-large;
	background: $teal;
	border-radius: $spacing-small;
	color: $gray;
	font-family: ApexRegular;
	font-size: 1.25em;
	border: none;
	cursor: pointer;
}

.button-small
{
	font-size: 0.75em;
	padding: $spacing-tiny $spacing-medium;
}

button:hover,
button:focus
{
	background: $forest;
	cursor: pointer;
}

a
{
	text-decoration: none;
	font-weight: bold;
	color: $teal;
	cursor: pointer;
}

a:active,
a:hover,
a:focus
{
	text-decoration: underline;
}
