@import "../../variables.scss";

.block
{
	display: inline-block;
	background-color: $teal;
	border-radius: 0px;
	padding: 0px $spacing-large $spacing-large $spacing-large;
	margin: 0px;
}

.block h2
{
	color: white;
}

.block button
{
	background: $black;
}

.block a
{
	color: $gray;
}

.block a:active,
.block a:hover,
.block a:focus,
{
	color: $black;
}

.block button:hover,
.block button:focus
{
	background: $lime	;
	color: $black;
}

@media only screen and (min-width: $mobile-break)
{
	.block
	{
		border-radius: 16px;
		padding: $spacing-large $spacing-huge $spacing-huge $spacing-huge;
	}
}
