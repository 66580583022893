@import "../../../../../../../variables.scss";

.map-player
{
	padding-bottom: $spacing-medium;
	text-align: center;
}

.map-player span
{
	display: inline-block;
	width: 200px;
	text-align: right;
	padding-right: $spacing-small;
}

.map-player select
{
	width: 200px;
	padding-left: $spacing-small;
}
