@import "../../../variables.scss";

.highHand
{
	text-align: center;
}

.sub
{
	display: inline-block;
	margin-top: $spacing-tiny;
}

.by
{
	font-weight: bold;
}
