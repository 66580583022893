@import "variables.scss";

.container
{
	padding: 0px;
	margin: 0px;
	top: 0px;
	left: 0px;
	right: 0px;
}
