@import "../../../variables.scss";

.chart
{
	width: 100%;
	height: 500px;
}

.chart h3
{
	padding: $spacing-large;
}

.recharts-cartesian-axis-ticks, .recharts-cartesian-axis-tick
{
	display: none;
}

@media (min-width: $mobile-break)
{
	.recharts-cartesian-axis-ticks, .recharts-cartesian-axis-tick
	{
		display: block;
	}
}
