$gray: #E4E4E4;
$lime: #B5C266;
$forest: #89B374;
$black: #2B2B2D;
$teal: #01ACB6;
$navy: #387490;
$purple: #702E52;
$red: #F2503B;
$gold: #FEBE28;

$spacing-nano: 4px;
$spacing-tiny: 8px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;
$spacing-huge: 64px;

$mobile-break: 768px;
$desktop-break: 1366px;
