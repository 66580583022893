@import "../../../variables.scss";

.buttons
{
	padding: $spacing-large 0px 0px 0px;
}

.centreButtons
{
	text-align: center;
}

.rightButtons
{
	text-align: right;
}

.buttons button
{
	min-width: 160px;
	padding: $spacing-tiny $spacing-medium;
	font-size: 1em;
}

.buttons button:not(:last-child)
{
	margin-right: $spacing-medium;
}
