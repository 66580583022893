@import "../../../../variables.scss";


.season-admin-container
{
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	padding: 0;
}

.season-admin-container .block
{
	flex-grow: 1;
}


@media only screen and (min-width: 768px)
{
	.season-admin-container
	{
		flex-direction: row;
		padding: $spacing-large;
	}

	.season-admin-container .block:not(:last-child)
	{
		margin-right: $spacing-medium;
	}

	.season-admin-container .block:not(:first-child)
	{
		margin-left: $spacing-medium;
	}

	.season-admin-container .block:not(:last-child)
	{
		margin-bottom: 0px;
	}
}
