
@import "../../../variables.scss";




.error
{
	color: $gold;
	font-weight: bold;
}
