// https://icons8.com/cssload/en/spinners/4
@import "../../../variables.scss";

.box {
  height: 140px;
}

.cssload-spin-box {
  position: relative;
  margin: auto;
  left: 0;
  top: 60px;
  bottom: 0;
  right: 0;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  box-shadow: 26px 26px $gold, -26px 26px $gray,
    -26px -26px $gold, 26px -26px $gray;
  -o-box-shadow: 26px 26px $gold, -26px 26px $gray,
    -26px -26px $gold, 26px -26px $gray;
  -ms-box-shadow: 26px 26px $gold, -26px 26px $gray,
    -26px -26px $gold, 26px -26px $gray;
  -webkit-box-shadow: 26px 26px $gold, -26px 26px $gray,
    -26px -26px $gold, 26px -26px $gray;
  -moz-box-shadow: 26px 26px $gold, -26px 26px $gray,
    -26px -26px $gold, 26px -26px $gray;
  animation: cssload-spin ease infinite 4.6s;
  -o-animation: cssload-spin ease infinite 4.6s;
  -ms-animation: cssload-spin ease infinite 4.6s;
  -webkit-animation: cssload-spin ease infinite 4.6s;
  -moz-animation: cssload-spin ease infinite 4.6s;
}

@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 26px 26px $gold, -26px 26px $gray,
      -26px -26px $gold, 26px -26px $gray;
  }
  25% {
    box-shadow: -26px 26px $gray, -26px -26px $gold,
      26px -26px $gray, 26px 26px $gold;
  }
  50% {
    box-shadow: -26px -26px $gold, 26px -26px $gray,
      26px 26px $gold, -26px 26px $gray;
  }
  75% {
    box-shadow: 26px -26px #dfdfdf, 26px 26px #4f4d49, -26px 26px #dfdfdf,
      -26px -26px #4f4d49;
  }
}

@-o-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 26px 26px $gold, -26px 26px $gray,
      -26px -26px $gold, 26px -26px $gray;
  }
  25% {
    box-shadow: -26px 26px $gray, -26px -26px $gold,
      26px -26px $gray, 26px 26px $gold;
  }
  50% {
    box-shadow: -26px -26px $gold, 26px -26px $gray,
      26px 26px $gold, -26px 26px $gray;
  }
  75% {
    box-shadow: 26px -26px #dfdfdf, 26px 26px #4f4d49, -26px 26px #dfdfdf,
      -26px -26px #4f4d49;
  }
}

@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 26px 26px $gold, -26px 26px $gray,
      -26px -26px $gold, 26px -26px $gray;
  }
  25% {
    box-shadow: -26px 26px $gray, -26px -26px $gold,
      26px -26px $gray, 26px 26px $gold;
  }
  50% {
    box-shadow: -26px -26px $gold, 26px -26px $gray,
      26px 26px $gold, -26px 26px $gray;
  }
  75% {
    box-shadow: 26px -26px #dfdfdf, 26px 26px #4f4d49, -26px 26px #dfdfdf,
      -26px -26px #4f4d49;
  }
}

@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 26px 26px $gold, -26px 26px $gray,
      -26px -26px $gold, 26px -26px $gray;
  }
  25% {
    box-shadow: -26px 26px $gray, -26px -26px $gold,
      26px -26px $gray, 26px 26px $gold;
  }
  50% {
    box-shadow: -26px -26px $gold, 26px -26px $gray,
      26px 26px $gold, -26px 26px $gray;
  }
  75% {
    box-shadow: 26px -26px #dfdfdf, 26px 26px #4f4d49, -26px 26px #dfdfdf,
      -26px -26px #4f4d49;
  }
}

@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 26px 26px $gold, -26px 26px $gray,
      -26px -26px $gold, 26px -26px $gray;
  }
  25% {
    box-shadow: -26px 26px $gray, -26px -26px $gold,
      26px -26px $gray, 26px 26px $gold;
  }
  50% {
    box-shadow: -26px -26px $gold, 26px -26px $gray,
      26px 26px $gold, -26px 26px $gray;
  }
  75% {
    box-shadow: 26px -26px #dfdfdf, 26px 26px #4f4d49, -26px 26px #dfdfdf,
      -26px -26px #4f4d49;
  }
}
