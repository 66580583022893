@import "../../../variables.scss";

#dialog-wrapper
{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

#dialog
{
	spacing: $spacing-large;
	position: relative;
	max-width: 500px;
	background: $gray;
	top: 50px;
	margin: auto;
	z-index: 10000;
	text-align: left;
	padding: 0px $spacing-large $spacing-large $spacing-large;
	color: $black;
}

@media only screen and (min-width: $mobile-break)
{
	#dialog
	{
		max-width: 700px;
	}
}
