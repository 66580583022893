@import "../../variables.scss";

.heading
{
	margin: $spacing-small;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: $spacing-medium;
}

.heading-logo
{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: $spacing-small;
}

.heading-additional
{
	width: 100%;
}

.logo
{
	display: inline-block;
	background-image: url('logo192.png');
	background-size: contain;
	height: 75px;
	width: 75px;
}

.heading h1
{
	font-size: 1.0em;
	display: inline-block;
	padding: 0px;
}

@media only screen and (min-width: $mobile-break)
{
	.heading
	{
		margin: $spacing-medium;
	}

	.logo
	{
		height: 100px;
		width: 100px;
	}

	.heading h1
	{
		font-size: 2em;
		display: inline-block;
		padding: 0px 0px 0px $spacing-medium;
	}

	.heading-additional
	{
		width: 50%;
	}
}

@media only screen and (min-width: $desktop-break)
{
	.heading
	{
		flex-direction: row;
	}
}
