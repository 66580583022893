@import "../../../../../variables.scss";


.event-upload-container
{
	width: 100%;
	padding: 0;
}

.event-scores-container
{
	text-align: center;
}

.event-upload-container .block
{
	flex-grow: 1;
}


@media only screen and (min-width: 768px)
{
	.event-upload-container
	{
		flex-direction: row;
		padding: $spacing-large;
	}

	.event-upload-container .block:not(:last-child)
	{
		margin-right: $spacing-medium;
	}

	.event-upload-container .block:not(:first-child)
	{
		margin-left: $spacing-medium;
	}

	.event-upload-container .block:not(:last-child)
	{
		margin-bottom: 0px;
	}
}
