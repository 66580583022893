@import "../../../variables.scss";

.formWhite span
{
	color: $gray;
}

.formBlack span
{
	color: $black;
}
