@import "../../../variables.scss";

.user-detail
{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: $spacing-small;
}

.user-email
{
	display: inline-block;
	font-size: 0.75em;
}

.user-detail button
{
	display: inline-block;
}
