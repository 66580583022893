@import "../../../../../variables.scss";

.hidden
{
	position: absolute;
	left: -100000px;
}

.shown
{
	position: relative;
	left: 0;
}

.grid
{
	width: 100%;
	display: flex;
	flex-direction: column;
}

.grid > div
{
	margin-top: $spacing-small;
}

.row
{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
	padding-bottom: $spacing-tiny;
	border-bottom: 1px solid rgba(255,255,255,0.25);
}

.row > a:not(:first-child)
{
	margin-left: $spacing-small;
}

.dataColumn
{
	width: 100%;
	flex-grow: 1;
	display: block;
	color: $gray;
}

.actionColumn
{
	text-align: right;
	min-width: 120px;
	flex-grow: 0;
	display: block;
}
